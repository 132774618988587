import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const FeatureWrapper = styled(Container)`
  .featuredContainer {
    margin: 0rem 0rem 0.8rem 0rem;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #ccc;
  }
  .content {
    padding-top: 0rem;
  }
  h4 > a {
    text-decoration: none !important;
    color: #333;
    margin-bottom: 0 !important;
    font-size: 1.2rem;
    font-weight: bold;
  }

  p.category, p.publish {
    color: #808080;
    font-size: 0.9rem !important;
    margin: 0;
  }

  p.description {
    margin: 0.7rem 0;
  }

  p.read-more {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 768px) {
    padding: 0rem !important;
    .heroImage {
      padding-right: 10rem;
      margin-bottom: 1.2rem;
    }
    h4 > a {
      font-size: 1.6rem;
      line-height: 0.9rem !important;
      font-weight: bold;
    }
  }

`;

export {
  FeatureWrapper,
};
