import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from '../components/seo';
import '../scss/custom.scss';
import MainLayout from '../layouts/main';
import FeatureArticles from '../components/widgets/featuredArticle';

const HomePage = () => {
  const query = graphql`
    query FeatureArticles {
      allMdx(
        filter: {frontmatter: {homepageFeatured: {eq: true}}},
        sort: {order: DESC, fields: frontmatter___date},
      ) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              category
              date(formatString: "MMMM Do, YYYY")
              description
              author
              hero_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const articles = data.allMdx.edges;

  return (
    <MainLayout>
      <Seo />
      <FeatureArticles articles={articles} />
    </MainLayout>
  )
};

export default HomePage;
