import React from 'react';
import { 
  Link 
} from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Col, Row } from 'react-bootstrap';
import { getKebabLink } from '../../../utils/kebabCase';
import { FeatureWrapper } from './styled';

const FeatureArticles = ({ articles }) => {
  return (
    <FeatureWrapper>
      {articles.map((article) => {
        const {
          title,
          description,
          category,
          date,
          hero_image,
        } = article.node.frontmatter; 
        const { slug } = article.node;
        const imageData = (hero_image)? getImage(hero_image.childImageSharp.gatsbyImageData): null;
        return (
          <Row className="featuredContainer">
            <Col xs={12} lg={4} className="heroImage">
              { imageData && <GatsbyImage image={imageData} alt={title} /> }
            </Col>
            <Col className="content">
              <h4><Link to={`/${slug}`} >{title}</Link></h4>
              <p className="category">Categorized in <Link to={getKebabLink(category, '/categories/')}>{category}</Link></p>
              <p className="publish" >Published {date}</p>
              <p className="description">{description}</p>
              <p className="read-more"><Link to={`/${slug}`} >Read more »</Link></p>
            </Col>
          </Row>
        );
      })}
    </FeatureWrapper>
  );
};


export default FeatureArticles;

